import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';
import { ButtonContent } from '../global/boton/ButtonContent';

import imgDefault from '../../assets/image/placeholder.png'


const Block_about = ({ title, text, text2, text3, text4, text5, image1, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='bg-[#efefef] py-[150px]' data-aos="fade-up">
            <div className='w-[95%] md:w-[90%] mx-auto'>
                <div className=' flex flex-col-reverse md:flex-row px-1 md:px-10 gap-7'>
                    <div className='w-auto h-auto flex justify-center items-center'>
                     <img src={ image1 ? image1 : imgDefault } alt="nnoimga" className='border-white border-[8px]'/>
                    </div>
                    <div className='w-full md:w-1/2 bg-white mr-0 -mb-20 mt-5 md:mr-2 md:-my-20 px-10 py-12 flex flex-col justify-center text-center md:text-start'>
                        {
                            sloganPrincipal ?
                                rpdata?.dbPrincipal?.licensed ?
                                    <h2 className='pb-3 capitalize'>
                                        {rpdata?.dbPrincipal?.licensed}
                                    </h2>
                                    : <h3 className='pb-10 capitalize'>
                                        {
                                            rpdata?.dbPrincipal?.exprYears ?
                                                `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                                                : 'we have many years of experience'
                                        }
                                    </h3>
                                :
                                <h2 className='pb-3 capitalize'>
                                    {
                                        title ? title :
                                            <span className='lowercase text-[18px]'>
                                                {`default title={'frase'}`} o
                                                {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                            </span>
                                    }
                                </h2>
                        }
                        <p className='pb-3'>
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>

                        <p className='pb-3'>
                            {
                                text2 ? text2 :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                        <p className='pb-3'>
                            {
                                text3 ? text3 :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                        <p className='pb-3'>
                            {
                                text4 ? text4 :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>

                        <p className='pb-3'>
                            {
                                text5 ? text5 :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>

                        <div>
                            <ButtonContent />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Block_about;